import React, { useState, useEffect } from "react";

const VerifyQuestion = (props) => {
  const [QuestionAnswer, setQuestionAnswer] = useState("");
  //
  //

  //
  //
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const order = props.order;
  const question = props.question;
  const product = props.order.Product;
  const commAccountNumbers = [
    order.accountNumber_1,
    order.accountNumber_2,
    order.accountNumber_3,
    order.accountNumber_4,
    order.accountNumber_5,
    order.accountNumber_6,
    order.accountNumber_7,
    order.accountNumber_8,
    order.accountNumber_9,
    order.accountNumber_10,
  ];

  const accountNumbercount = Object.values(commAccountNumbers).findIndex(
    (element) => element === ""
  );

  //
  //
  function handleClickTrue(index) {
    const updatedYesArray = props.YesArray.map((c, i) => {
      if (i === index) {
        // Increment the clicked counter
        return true;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    props.updateYesArray(updatedYesArray);
  }
  //
  //
  //
  function handleClickFalse(index) {
    const updatedYesArray = props.YesArray.map((c, i) => {
      if (i === index) {
        // Increment the clicked counter
        return false;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    props.updateYesArray(updatedYesArray);
  }
  //
  //
  //

  function QuestionNo() {
    handleClickTrue(props.index + 2);
    setTimeout(function () {
      setQuestionAnswer("Yes");
    }, 10);
  }

  function QuestionYes() {
    handleClickFalse(props.index + 2);
    setTimeout(function () {
      setQuestionAnswer("No");
    }, 10);
  }

  //
  //
  // Dynamic Shortcodes
  //
  //
  //
  var dynamicQuestion = question
    .replace(/[\[\]]customer[\[\]]/g, order.firstName + " " + order.lastName)
    .replace(/[\[\]]date[\[\]]/g, new Date().toLocaleString())
    .replace(/[\[\]]rate[\[\]]/g, "$" + product.rate)
    .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
    .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
    .replace(/[\[\]]utility[\[\]]/g, product.utility.name)
    .replace(
      /[\[\]]utility_tel[\[\]]/g,
      formatPhoneNumber(product.utility.phone)
    )
    .replace(/[\[\]]utility_short[\[\]]/g, product.utility.nickname)
    .replace(/[\[\]]accNumber1[\[\]]/g, order.accountNumber_1)
    .replace(/[\[\]]addr1[\[\]]/g, order.serviceAddress)
    .replace(/[\[\]]addr2[\[\]]/g, order.serviceSuite)
    .replace(/[\[\]]city[\[\]]/g, order.serviceCity)
    .replace(/[\[\]]state[\[\]]/g, order.serviceState)
    .replace(/[\[\]]zipcode[\[\]]/g, order.serviceZip)
    .replace(/[\[\]]bill_addr1[\[\]]/g, order.billingAddress)
    .replace(/[\[\]]bill_addr2[\[\]]/g, order.billingSuite)
    .replace(/[\[\]]bill_city[\[\]]/g, order.billingCity)
    .replace(/[\[\]]bill_state[\[\]]/g, order.billingState)
    .replace(/[\[\]]bill_zipcode[\[\]]/g, order.billingZip)
    .replace(/[\[\]]term[\[\]]/g, product.term)
    .replace(/[\[\]]monthlyFee[\[\]]/g, "$" + product.monthlyFee)
    .replace(/[\[\]]orderNumber[\[\]]/g, order.orderNumber);
  //
  //
  var commercialDynamicQuestion = question
    .replace(
      /[\[\]]customer[\[\]]/g,
      order.primaryFirstName + " " + order.primaryLastName
    )
    .replace(/[\[\]]date[\[\]]/g, new Date().toLocaleString())
    .replace(/[\[\]]rate[\[\]]/g, "$" + product.rate)
    .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
    .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
    .replace(/[\[\]]utility[\[\]]/g, product.utility.name)
    .replace(
      /[\[\]]utility_tel[\[\]]/g,
      formatPhoneNumber(product.utility.phone)
    )
    .replace(/[\[\]]utility_short[\[\]]/g, product.utility.nickname)
    .replace(/[\[\]]numberAccounts[\[\]]/g, accountNumbercount - 1)
    .replace(/[\[\]]accNumber1[\[\]]/g, order.accountNumber_1)
    .replace(/[\[\]]accNumber2[\[\]]/g, order.accountNumber_2 + ",")
    .replace(/[\[\]]accNumber3[\[\]]/g, order.accountNumber_3 + ",")
    .replace(/[\[\]]accNumber4[\[\]]/g, order.accountNumber_4 + ",")
    .replace(/[\[\]]accNumber5[\[\]]/g, order.accountNumber_5 + ",")
    .replace(/[\[\]]accNumber6[\[\]]/g, order.accountNumber_6 + ",")
    .replace(/[\[\]]accNumber7[\[\]]/g, order.accountNumber_7 + ",")
    .replace(/[\[\]]accNumber8[\[\]]/g, order.accountNumber_8 + ",")
    .replace(/[\[\]]accNumber9[\[\]]/g, order.accountNumber_9 + ",")
    .replace(/[\[\]]accNumber10[\[\]]/g, order.accountNumber_10 + ",")
    .replace(/[\[\]]addr1[\[\]]/g, order.serviceAddress)
    .replace(/[\[\]]addr2[\[\]]/g, order.serviceSuite)
    .replace(/[\[\]]city[\[\]]/g, order.serviceCity)
    .replace(/[\[\]]state[\[\]]/g, order.serviceState)
    .replace(/[\[\]]zipcode[\[\]]/g, order.serviceZip)
    .replace(/[\[\]]bill_addr1[\[\]]/g, order.billingAddress)
    .replace(/[\[\]]bill_addr2[\[\]]/g, order.billingSuite)
    .replace(/[\[\]]bill_city[\[\]]/g, order.billingCity)
    .replace(/[\[\]]bill_state[\[\]]/g, order.billingState)
    .replace(/[\[\]]bill_zipcode[\[\]]/g, order.billingZip)
    .replace(/[\[\]]term[\[\]]/g, product.term)
    .replace(/[\[\]]monthlyFee[\[\]]/g, "$" + product.monthlyFee)
    .replace(/[\[\]]orderNumber[\[\]]/g, order.orderNumber);
  //
  //
  return (
    <>
      {product.dwelling === "Commercial" ? (
        <div class="pr-2 pt-10 border-b pb-12 lg:flex lg:items-center">
          <div class="lg:w-0 lg:flex-1">
            <h2 class="text-2xl font-semibold tracking-tight text-gray-900 ">
              {commercialDynamicQuestion}
            </h2>
          </div>
          <div class="mt-8 lg:mt-0 lg:ml-8">
            <div class="sm:flex">
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                {QuestionAnswer === "Yes" ? (
                  <button
                    type="button"
                    disabled
                    class="rounded-full transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-green-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Yes
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={QuestionNo}
                      class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      onMouseDown={QuestionNo}
                      class=" lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Yes
                    </button>
                  </>
                )}
              </div>
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                {QuestionAnswer === "No" ? (
                  <button
                    disabled
                    class="transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-red-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    No
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onMouseDown={QuestionYes}
                      class="lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                    >
                      No{" "}
                    </button>
                    <button
                      type="button"
                      onClick={QuestionYes}
                      class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                    >
                      No{" "}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="pr-2 pt-10 border-b pb-12 lg:flex lg:items-center">
          <div class="lg:w-0 lg:flex-1">
            <h2 class="text-2xl font-semibold tracking-tight text-gray-900 ">
              {dynamicQuestion}
            </h2>
          </div>
          <div class="mt-8 lg:mt-0 lg:ml-8">
            <div class="sm:flex">
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                {QuestionAnswer === "Yes" ? (
                  <button
                    type="button"
                    disabled
                    class="rounded-full transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-green-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Yes
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={QuestionNo}
                      class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      onMouseDown={QuestionNo}
                      class="lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Yes
                    </button>
                  </>
                )}
              </div>
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                {QuestionAnswer === "No" ? (
                  <button
                    disabled
                    class="transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-red-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    No
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={QuestionYes}
                      class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                    >
                      No{" "}
                    </button>
                    <button
                      type="button"
                      onMouseDown={QuestionYes}
                      class="lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                    >
                      No{" "}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyQuestion;
