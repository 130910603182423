import React, { useState, useEffect } from "react";

const VerifyQuestionTime = (props) => {
  const [QuestionAnswer, setQuestionAnswer] = useState("");

  //
  //
  function handleClickTrue(index) {
    const updatedYesArray = props.YesArray.map((c, i) => {
      if (i === index) {
        // Increment the clicked counter
        return true;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    props.updateYesArray(updatedYesArray);
  }
  //
  //
  //
  function handleClickFalse(index) {
    const updatedYesArray = props.YesArray.map((c, i) => {
      if (i === index) {
        // Increment the clicked counter
        return false;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    props.updateYesArray(updatedYesArray);
  }
  //
  //
  //

  function QuestionNo() {
    handleClickTrue(0);
    setTimeout(function () {
      setQuestionAnswer("Yes");
    }, 10);
  }

  function QuestionYes() {
    handleClickFalse(0);
    setTimeout(function () {
      setQuestionAnswer("No");
    }, 10);
  }

  return (
    <div>
      <div class="time pr-2 pt-10 border-b pb-12 lg:flex lg:items-center">
        <div class="lg:w-0 lg:flex-1">
          <h2 class="text-2xl font-semibold tracking-tight text-gray-900 ">
            Can you confirm that the date and time you visited this page was
            {` `}
            {new Date().toLocaleString()}?
          </h2>
        </div>
        <div class="mt-8 lg:mt-0 lg:ml-8">
          <div class="sm:flex">
            <div class="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              {QuestionAnswer === "Yes" ? (
                <button
                  type="button"
                  disabled
                  class="rounded-full transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-green-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Yes
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={QuestionNo}
                    class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onMouseDown={QuestionNo}
                    class="lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-green-700 lg:hover:text-white lg:hover:bg-green-700 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              {QuestionAnswer === "No" ? (
                <button
                  disabled
                  class="transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-red-600 py-4 px-12 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  No
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={QuestionYes}
                    class="hidden lg:block transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                  >
                    No{" "}
                  </button>
                  <button
                    type="button"
                    onMouseDown={QuestionYes}
                    class="lg:hidden transition ease-in-out duration-150 flex w-full items-center justify-center rounded-full bg-white ring-1 ring-inset ring-gray-500 lg:hover:ring-red-700 lg:hover:text-white lg:hover:bg-red-600 py-4 px-12 text-base font-medium text-gray-500 focus:outline-none"
                  >
                    No{" "}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyQuestionTime;
