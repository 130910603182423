import React, { useState, useEffect } from "react";
import axios from "axios";

const VerifyCompleteComm = (props) => {
  if (typeof window === "undefined") {
    var urlParams = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var urlParams = new URLSearchParams(window.location.search);
  }
  //
  //
  //
  const [verifiedOrder, setverifiedOrder] = useState("");
  const [emailConfirmation, setemailConfirmation] = useState({
    response: "",
    time: "",
  });
  const [loadingDone, setloadingDone] = useState(false);
  //
  //
  //
  //Parameters needed to securely search order
  //
  const paramOrder = urlParams.get("o");
  const paramSupplier = urlParams.get("s");

  //
  //
  //
  //
  //
  //
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (paramSupplier) {
    case "Greenwave-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0",
      });
      break;
    case "Callective-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5pbEAAQoRZJRKoRzwaoIToIVr0UwhfwQrb",
      });
      break;
    case "Kratos-Ohio":
      var client = new faunadb.Client({
        secret: "fnAFYC6WwiAAQI93MiffluUh1iR_1I2AoxFZXj-6",
      });
      break;
    case "Kratos-Michigan":
      var client = new faunadb.Client({
        secret: "fnAFYC6M1yAAQJ8ocro91gzUxn4A6XkLOsgMbh01",
      });
      break;

    case "Kratos-Duke":
      var client = new faunadb.Client({
        secret: "fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_",
      });
      break;

    default:
      var client = new faunadb.Client({
        secret: "",
      });
      break;
  }
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //
  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("order-details-by-ref"), Number(paramOrder))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setverifiedOrder(allOrders.data);
    }
  };

  var matchedOrder = "default";

  switch (typeof verifiedOrder === "object") {
    case verifiedOrder === undefined:
      var matchedOrder = "existing orders is empty";
      break;
    case true:
      var matchedOrder = verifiedOrder[0].data;
      var order = matchedOrder;
      var question = matchedOrder.Product.script.tpvQuestions;
      var product = matchedOrder.Product;
      var productName = matchedOrder.Product.name;
      var productRate = matchedOrder.Product.rate;
      var productTerm = matchedOrder.Product.term;
      var productMonthlyFee = matchedOrder.Product.monthlyFee;
      var productTerminationFee = matchedOrder.Product.terminationFee;
      var productVendor = matchedOrder.Product.vendor.name;
      var commercialDynamicQuestion = question
        .toString()
        .replace(
          /[\[\]]customer[\[\]]/g,
          order.primaryFirstName + " " + order.primaryLastName
        )
        .replace(/[\[\]]date[\[\]]/g, new Date().toLocaleString())
        .replace(/[\[\]]rate[\[\]]/g, "$" + product.rate)
        .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
        .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
        .replace(/[\[\]]utility[\[\]]/g, product.utility.name)
        .replace(/[\[\]]utility_short[\[\]]/g, product.utility.nickname)
        .replace(/[\[\]]addr1[\[\]]/g, order.serviceAddress)
        .replace(/[\[\]]addr2[\[\]]/g, order.serviceSuite)
        .replace(/[\[\]]city[\[\]]/g, order.city)
        .replace(/[\[\]]state[\[\]]/g, order.serviceState)
        .replace(/[\[\]]zipcode[\[\]]/g, order.serviceZip)
        .replace(/[\[\]]bill_addr1[\[\]]/g, order.billingAddress)
        .replace(/[\[\]]bill_addr2[\[\]]/g, order.billingSuite)
        .replace(/[\[\]]bill_city[\[\]]/g, order.billingCity)
        .replace(/[\[\]]bill_state[\[\]]/g, order.billingState)
        .replace(/[\[\]]bill_zipcode[\[\]]/g, order.billingZip)
        .replace(/[\[\]]term[\[\]]/g, product.term)
        .replace(/[\[\]]monthlyFee[\[\]]/g, "$" + product.monthlyFee)
        .replace(/[\[\]]orderNumber[\[\]]/g, order.orderNumber);
      break;
  }
  //
  //

  //
  //
  const orderLoadingDone = () => {
    setTimeout(function () {
      setloadingDone(true);
    }, 2000);
  };
  //
  //
  //
  //
  //
  //
  //
  //

  useEffect(() => {
    if (typeof verifiedOrder === "string") {
      getAllOrders();
      orderLoadingDone();
    }

    setTimeout(function () {
      confirmationEmail();
    }, 2000);
  }, [verifiedOrder]);

  //
  //
  //

  //
  //
  //
  //

  //
  //

  //
  //
  //
  const confirmationEmail = async () => {
    const inputDate = new Date().toGMTString();
    let response = await axios.post(
      // Need to make dynamic for supplier paramSupplier//
      `/.netlify/functions/comm-form-${paramSupplier.toLowerCase()}`,
      {
        businessName: matchedOrder.businessName.replace(/-/g, " "),
        primaryName:
          matchedOrder.primaryFirstName + " " + matchedOrder.primaryLastName,
        dmPosition: matchedOrder.dmPosition,
        firstName: matchedOrder.firstName,
        lastName: matchedOrder.lastName,
        billing_address: matchedOrder.billingAddress,
        billing_suite: matchedOrder.billingSuite,
        billing_city: matchedOrder.billingCity,
        billing_state: matchedOrder.billingState,
        billing_zip: matchedOrder.billingZip,
        business_address: matchedOrder.businessAddress,
        business_suite: matchedOrder.businessSuite,
        business_city: matchedOrder.businessCity,
        business_state: matchedOrder.businessState,
        business_zip: matchedOrder.businessZip,
        service_address: matchedOrder.serviceAddress,
        service_suite: matchedOrder.serviceSuite,
        service_city: matchedOrder.serviceCity,
        service_state: matchedOrder.serviceState,
        verification: matchedOrder.deliveryMethod,
        email: matchedOrder.businessEmail,
        verifyEmail: matchedOrder.verifyEmail,
        service_zip: matchedOrder.serviceZip,
        phone: matchedOrder.billingTel,
        phoneType: matchedOrder.telType,
        language: matchedOrder.Language,
        timeEnrolled: inputDate,
        //////////// Product Stuff /////////////////
        product_name: productName,
        product_rate: productRate,
        product_term: productTerm,
        product_monthly_fee: productMonthlyFee,
        product_termination_fee: productTerminationFee,
        product_vendor: productVendor,
        tpv_questions: commercialDynamicQuestion,
        //////////// Account Numbers ////////////
        accountNumber_1: matchedOrder.accountNumber_1,
        accountNumber_2: matchedOrder.accountNumber_2,
        accountNumber_3: matchedOrder.accountNumber_3,
        accountNumber_4: matchedOrder.accountNumber_4,
        accountNumber_5: matchedOrder.accountNumber_5,
        accountNumber_6: matchedOrder.accountNumber_6,
        accountNumber_7: matchedOrder.accountNumber_7,
        accountNumber_8: matchedOrder.accountNumber_8,
        accountNumber_9: matchedOrder.accountNumber_9,
        accountNumber_10: matchedOrder.accountNumber_10,
        order: matchedOrder,
        supplier: paramSupplier,
        signature: matchedOrder.signaturePng,
      }
    );

    const emailResponse = await response;

    setTimeout(function () {
      setemailConfirmation({
        ...emailConfirmation,
        response: emailResponse,
        time: inputDate,
      });
    }, 2000);
  };

  //
  //
  //
  //
  return (
    <>
      <div class="animate-fade bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-orange-300 to-amber-200 flex items-center justify-center h-screen">
        <div class="animate-fade success bg-white p-20 shadow-xl rounded-3xl  ">
          <div class="text-cyan-400 flex flex-col items-center space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="animate-bounce mb-3 w-28 h-28"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
            <h1 class="text-center text-cyan-900 text-6xl font-bold">
              Order Complete & Verified{" "}
            </h1>{" "}
            <p class="pt-2 max-w-3xl text-center text-gray-700 text-2xl">
              Thank you for completing third party verification,
              <span class="pt-2 block font-bold">
                your order number is:{" "}
                <span class="text-cyan-500">{matchedOrder.orderNumber}.</span>
              </span>{" "}
            </p>
            <p class="pb-6 pt-6 max-w-3xl text-center text-gray-700 text-2xl">
              An email has been sent to{" "}
              <span class="text-gray-400 font-medium underline">
                {matchedOrder.businessEmail}
              </span>{" "}
              with your order details, please contact {props.supplier} with any
              questions.
            </p>
            {emailConfirmation.response.status === 200 ? (
              <p class="pt-3 max-w-3xl text-center text-green-700 text-xs border-t">
                An email has been sent successfully on {emailConfirmation.time}
              </p>
            ) : (
              <>
                <button
                  type="button"
                  disabled
                  class="pt-3 max-w-3xl text-center text-gray-500 text-xs border-t "
                >
                  Sending confirmation email...
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCompleteComm;
