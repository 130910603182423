import React, { useState, useEffect, useRef } from "react";
import VerifyQuestionTime from "../components/Verify/VerifyQuestionTime";
import VerifyQuestionOrder from "../components/Verify/VerifyQuestionOrder";
import VerifyQuestion from "../components/Verify/VerifyQuestion";
import SignaturePad from "react-signature-canvas";
import OpenGateLogo from "../images/Wordmark-Logo-Blue.svg";
import VerifyCompleteComm from "../components/Verify/VerifyCompleteComm";

const VerifyPageCommercial = () => {
  if (typeof window === "undefined") {
    var urlParams = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var urlParams = new URLSearchParams(window.location.search);
  }
  //
  const [SaveState, setSaveState] = useState("unsaved");
  const [foundCustomerOrder, setfoundCustomerOrder] = useState("");
  const [foundOrder, setfoundOrder] = useState("");
  //
  //
  //
  useEffect(() => {
    getAllOrders();
    orderLoadingDone();
  }, []);
  //
  //
  // Param Shenanigans //
  //
  // http://localhost:8000/verifyScreen/?o=2824350195&ln=blahh&s=Greenwave-Energy
  //
  //
  //
  //
  //Parameters needed to securely search order
  //
  const paramOrder = urlParams.get("o");
  const paramLastName = urlParams.get("ln");
  const paramSupplier = urlParams.get("s");

  //
  //
  //
  //
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (paramSupplier) {
    case "Greenwave-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0",
      });
      break;
    case "Callective-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5pbEAAQoRZJRKoRzwaoIToIVr0UwhfwQrb",
      });
      break;
    case "Kratos-Ohio":
      var client = new faunadb.Client({
        secret: "fnAFYC6WwiAAQI93MiffluUh1iR_1I2AoxFZXj-6",
      });
      break;
    case "Kratos-Michigan":
      var client = new faunadb.Client({
        secret: "fnAFYC6M1yAAQJ8ocro91gzUxn4A6XkLOsgMbh01",
      });
      break;
    case "Kratos-Duke":
      var client = new faunadb.Client({
        secret: "fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_",
      });
      break;

    default:
      var client = new faunadb.Client({
        secret: "",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  const [loadingDone, setloadingDone] = useState(false);
  const [areQuestionsAnswered, setareQuestionsAnswered] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [gpsData, setgpsData] = useState({
    lat: "",
    long: "",
    accuracy: "",
  });
  //
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //
  /*const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection(COLLECTION_NAME)), {
            size: 100000,
          }),
          q.Lambda((x) => q.Get(x))
        )
      );
      
      setfoundCustomerOrder(allOrders.data);
    }
  };*/

  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("order-details-by-ref"), Number(paramOrder))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setfoundCustomerOrder(allOrders.data);
    }
  };

  var matchedOrder = "default";
  var YesCountNeededToVerify = "";

  switch (typeof foundCustomerOrder === "object") {
    case false:
      var matchedOrder = "existing orders is empty";
      break;
    case true:
      var matchedOrder = foundCustomerOrder[0];
      var YesCountNeededToVerify =
        foundCustomerOrder[0].data.Product.script.tpvQuestions.filter((n) => n);
      break;
  }

  //
  //
  //
  //
  //
  //
  // Set Order Loading Status
  //
  //
  //
  const orderLoadingDone = () => {
    setTimeout(function () {
      setloadingDone(true);
    }, 2000);
  };
  //
  //
  //
  //
  //
  // Count yes answers for verification on submit button //
  // Question Counter //
  let initialYesArray = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0,
  ];
  //
  //

  //

  //
  const [YesCount, setYesCount] = useState(0);
  const [YesArray, setYesArray] = useState(initialYesArray);
  //
  //
  const newYesCount = YesArray.filter((x) => x === true).length;
  //
  //
  //
  //
  //
  //Update Database Entry//
  //
  //
  //
  const updateOrderEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    await client.query(
      q.Update(
        q.Ref(q.Collection(COLLECTION_NAME), matchedOrder.ref.value.id),
        {
          data: {
            isThisVerified: true,
            timeVerified: inputDate,
            orderStatus: "Complete",
            signaturePng: imageURL,
          },
        }
      )
    );
  };
  //
  //
  //
  function orderVerification() {
    setTimeout(() => {
      setSaveState("saving");
    }, 100);
    setTimeout(() => {
      updateOrderEntry();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      setSaveState("thank-you");
    }, 4500);
  }
  //
  //
  //
  // Clear Signature
  var sigPad = useRef({});
  function clear() {
    sigPad.current.clear();
    setImageURL(null);
  }
  //
  //
  //
  // Save Signature
  const saveSignature = () => {
    const URL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(URL);
  };
  //
  //
  //
  //
  //
  //
  if (loadingDone === false && matchedOrder === "existing orders is empty") {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading Order</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load your order.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div>
        {SaveState === "thank-you" ||
        matchedOrder.data.orderStatus === "Complete" ? (
          <VerifyCompleteComm />
        ) : (
          <div class="overflow-hidden relative h-full animate-fade bg-gray-100">
            <div class="text-center pt-12 pb-12 lg:pb-0 sm:col-span-1">
              <dt class="text-xl font-medium text-gray-500">
                Enrollment/Order #
              </dt>
              <dd class="mt-1 text-4xl font-bold text-gray-600">
                {matchedOrder.data.orderNumber}
              </dd>
            </div>
            <div class="mx-auto max-w-7xl py-2 px-4 sm:px-6 lg:py-14 lg:px-8">
              <div class="w-full mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div class="space-y-6 lg:col-span-3 lg:col-start-1">
                  <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        class="text-lg font-medium leading-6 text-gray-900"
                      >
                        Order Information
                      </h2>
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Thank you for your recent order with{" "}
                        {matchedOrder.data.supplier}. Verify all information is
                        correct and select YES or NO questions below to complete
                        your order.
                      </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Business Name
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.businessName.replace(/-/g, " ")}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Primary Contact Full Name
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.primaryFirstName +
                              ` ` +
                              matchedOrder.data.primaryLastName}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            DM Position
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.dmPosition}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Language
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.Language}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Billing Telephone
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.billingTel}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Email
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.businessEmail}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            {matchedOrder.data.Product.utility.accountFieldName}{" "}
                            <span class="text-xs text-gray-500">
                              - up to 10
                            </span>
                          </dt>

                          <div class="flex justify-start">
                            <div class="">
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_1}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_2}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_3}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_4}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_5}
                              </span>
                            </div>
                            <div class="ml-5">
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_6}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_7}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_8}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_9}
                              </span>
                              <span class="block mt-1 text-base font-bold text-gray-900">
                                {" "}
                                {matchedOrder.data.accountNumber_10}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Business Address
                          </dt>
                          <dd class="mt-1 text-base font-bold text-gray-900">
                            {matchedOrder.data.businessAddress +
                              ` ` +
                              matchedOrder.data.businessSuite +
                              "," +
                              ` ` +
                              matchedOrder.data.businessCity +
                              ` ` +
                              matchedOrder.data.businessState +
                              ` ` +
                              matchedOrder.data.businessZip}
                          </dd>
                        </div>

                        {matchedOrder.data.isServiceAddBusiness === true ? (
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Service Address
                            </dt>
                            <dd class="mt-1 text-base font-bold text-gray-900">
                              {matchedOrder.data.businessAddress +
                                ` ` +
                                matchedOrder.data.businessSuite +
                                "," +
                                ` ` +
                                matchedOrder.data.businessCity +
                                ` ` +
                                matchedOrder.data.businessState +
                                ` ` +
                                matchedOrder.data.businessZip}
                            </dd>
                          </div>
                        ) : (
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Service Address
                            </dt>
                            <dd class="mt-1 text-base font-bold text-gray-900">
                              {matchedOrder.data.serviceAddress +
                                ` ` +
                                matchedOrder.data.serviceSuite +
                                "," +
                                ` ` +
                                matchedOrder.data.serviceCity +
                                ` ` +
                                matchedOrder.data.serviceState +
                                ` ` +
                                matchedOrder.data.serviceZip}
                            </dd>
                          </div>
                        )}

                        {matchedOrder.data.isBillingAddService === true ? (
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Billing Address
                            </dt>
                            <dd class="mt-1 text-base font-bold text-gray-900">
                              {matchedOrder.data.businessAddress +
                                ` ` +
                                matchedOrder.data.businessSuite +
                                "," +
                                ` ` +
                                matchedOrder.data.businessCity +
                                ` ` +
                                matchedOrder.data.businessState +
                                ` ` +
                                matchedOrder.data.businessZip}
                            </dd>
                          </div>
                        ) : (
                          <div class="sm:col-span-1">
                            <dt class="text-sm font-medium text-gray-500">
                              Billing Address
                            </dt>
                            <dd class="mt-1 text-base font-bold text-gray-900">
                              {matchedOrder.data.billingAddress +
                                ` ` +
                                matchedOrder.data.billingSuite +
                                "," +
                                ` ` +
                                matchedOrder.data.billingCity +
                                ` ` +
                                matchedOrder.data.billingState +
                                ` ` +
                                matchedOrder.data.billingZip}
                            </dd>
                          </div>
                        )}

                        <div class="bg-amber-100 text-center p-3 rounded-md sm:col-span-2">
                          <dt class=" text-base font-medium text-gray-500">
                            Commercial Product Selected
                          </dt>
                          <dd class="mt-1 text-lg font-bold text-gray-900">
                            {matchedOrder.data.Product.name} -
                            {matchedOrder.data.Product.term} - $
                            {matchedOrder.data.Product.rate}/{" "}
                            {matchedOrder.data.Product.unitMeasurement}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>

              {areQuestionsAnswered === false ? (
                <>
                  <div class="mt-10 w-full sm:w-1/4 mx-auto text-center rounded-md bg-yellow-50 px-10 py-3 text-lg font-medium text-yellow-800 ring-1 ring-inset ring-yellow-800/50">
                    <h2> Step 1: Questions </h2>
                  </div>
                  <div class="py-6">
                    <VerifyQuestionTime
                      YesCount={YesCount}
                      setYesCount={setYesCount}
                      updateYesArray={setYesArray}
                      YesArray={YesArray}
                    />
                    <VerifyQuestionOrder
                      YesCount={YesCount}
                      setYesCount={setYesCount}
                      updateYesArray={setYesArray}
                      YesArray={YesArray}
                    />

                    {matchedOrder.data.Product.script.tpvQuestions
                      .filter((n) => n)
                      .map((post, index) => {
                        /*  
    matchedOrder.data.Product.script.tpvQuestions.filter((n) => n)
  );*/

                        return (
                          <VerifyQuestion
                            key={post}
                            index={index}
                            YesCount={YesCount}
                            setYesCount={setYesCount}
                            updateYesArray={setYesArray}
                            question={post}
                            YesArray={YesArray}
                            order={matchedOrder.data}
                          />
                        );
                      })}
                  </div>
                  {YesCountNeededToVerify.length + 2 === newYesCount &&
                  YesArray.includes(false === false) ? (
                    <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="button"
                        onClick={() => setareQuestionsAnswered(true)}
                        class="flex w-full items-center justify-center rounded-md   bg-blue-700 py-5 px-12 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
                      >
                        Add Signature
                      </button>
                    </div>
                  ) : (
                    <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="button"
                        disabled
                        class="flex w-full items-center justify-center rounded-md opacity-60 bg-blue-700 py-5 px-12 text-xl font-medium text-white focus:outline-none "
                      >
                        Please answer all questions
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div class="animate-fade pt-10">
                  <div class="w-full sm:w-1/4 mx-auto text-center rounded-md bg-purple-50 px-10 py-3 text-lg font-medium text-purple-800 ring-1 ring-inset ring-purple-800/50">
                    <h2> Step 2: Signature </h2>
                  </div>
                  <h2 class="text-center text-2xl mt-6 mb-4 font-semibold tracking-tight text-gray-500 ">
                    Please use your finger or mouse to sign here and finalize
                    your order
                  </h2>

                  <div class="bg-white max-w-6xl mx-auto mb-7 border border-black">
                    <SignaturePad
                      ref={sigPad}
                      onEnd={saveSignature}
                      penColor="blue"
                      throttle="1"
                      minWidth=".1"
                      maxWidth="7"
                      canvasProps={{
                        width: 1152,
                        height: 300,
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                  <div class="mb-8 mx-auto flex justify-end mr-8 mt-3 rounded-md sm:mt-0 hover:underline sm:flex-shrink-0">
                    <button
                      type="button"
                      onClick={clear}
                      class="text-base font-medium text-blue-600 "
                    >
                      Clear Signature
                    </button>
                  </div>

                  {YesCountNeededToVerify.length + 2 === newYesCount &&
                  imageURL !== null ? (
                    <>
                      {SaveState !== "saving" && SaveState !== "saved" ? (
                        <div>
                          {" "}
                          <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                            <button
                              type="button"
                              onClick={orderVerification}
                              class="hidden lg:flex w-full items-center justify-center rounded-md   bg-blue-700 py-5 px-12 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
                            >
                              Complete Verification
                            </button>
                            <button
                              type="button"
                              onMouseDown={orderVerification}
                              class="flex lg:hidden w-full items-center justify-center rounded-md   bg-blue-700 py-5 px-12 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
                            >
                              Complete Verification
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {SaveState === "saving" ? (
                            <div class="animate-fade text-center mt-4 flex sm:mt-0">
                              <button
                                disabled
                                type="button"
                                class=" w-full order-0 rounded-md bg-blue-700 px-8 py-5 text-lg font-medium text-white  focus:outline-none sm:order-1"
                              >
                                <svg
                                  role="status"
                                  class="inline mr-3 w-7 h-7 text-white animate-spin"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="blue-700"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                Verifying...
                              </button>
                            </div>
                          ) : (
                            <div class="animate-fade text-center mt-4 flex sm:mt-0">
                              <button
                                disabled
                                type="button"
                                class=" w-full order-0 rounded-md bg-green-800 px-8 py-5 text-lg font-medium text-white  focus:outline-none sm:order-1"
                              >
                                <svg
                                  role="status"
                                  class="inline mr-3 w-7 h-7 text-white animate-bounce"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                  />
                                </svg>
                                Order Complete
                              </button>
                            </div>
                          )}

                          <div class="hidden" />
                        </>
                      )}
                    </>
                  ) : (
                    <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="button"
                        disabled
                        class="flex w-full items-center justify-center rounded-md   bg-blue-700 opacity-60 py-5 px-12 text-xl font-medium text-white  focus:outline-none "
                      >
                        Please Sign Above
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div class="mt-14 items-center w-full inline-flex justify-center opacity-30">
                <span class="">Powered By </span>
                <img class="w-1/6" src={OpenGateLogo} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default VerifyPageCommercial;
